import { useActiveBrandId, useAuth } from 'auth'
import { NextSeo } from 'next-seo'
import { useRouter } from 'next/router'
import { Fragment } from 'react'
import { useEffectOnce } from 'react-use'
import { Box, LoginBox, VAULT_APP_NAME } from 'ui'

export default function LoginPage() {
    const router = useRouter()
    const { isAuthenticated } = useAuth()
    const { activeBrandId } = useActiveBrandId()

    useEffectOnce(() => {
        if (isAuthenticated && activeBrandId) {
            router.replace(`/dashboard/brand/${activeBrandId}`)
        }
    })

    return (
        <Fragment>
            <NextSeo title="Vault - Login" />
            <Box
                bg={'raven_black'}
                padding={'sm'}
                alignItems="center"
                css={{
                    width: '95%',
                    maxWidth: '650rem',
                    m: '0 auto',
                    position: 'fixed',
                    top: '50%',
                    right: 0,
                    left: 0,
                    borderRadius: '30rem',
                    transform: 'translateY(-50%)',
                    '@md': {
                        padding: '40rem 60rem'
                    }
                }}
                boxShadowed
            >
                <LoginBox appName={VAULT_APP_NAME} />
            </Box>
        </Fragment>
    )
}
